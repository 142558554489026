<template>
    <div id="Dashboard" class="container-fluid p-3 m-0" style="min-height: 100vh; border-left: 4px solid white">

        <!---Buttons -->
        <div class="row py-4  px-2 bg-secondary my-2 rounded">
            <div class="col-12 col-md-4">
                <button @click.stop="changeReport('incidents')"
                    class="btn py-3 w-100"
                    :class="[type == 'incidents' ? 'btn-primary' : 'btn-secondary border']"
                    >
                    Incidencias
                </button>
            </div>
            <div class="col-12 col-md-4">
                <button @click.stop="changeReport('responsibles')"
                    class="btn py-3 w-100"
                    :class="[type == 'responsibles' ? 'btn-primary' : 'btn-secondary border']">
                    Responsables
                </button>
            </div>
            <div class="col-12 col-md-4">
                <button @click.stop="changeReport('vehicles')"
                    class="btn py-3 w-100"
                    :class="[type == 'vehicles' ? 'btn-primary' : 'btn-secondary border']">
                    Unidades
                </button>
            </div>
        </div>

        <!---Dates search -->
        <div class="row p-0 my-4">

            <div @click.stop="openDatepicker('init')" 
                class="col-12 py-3 my-2 col-lg my-lg-0  py-0 bg-dark-gray d-flex"
                style="border-radius: 15px;">
                <div class="row align-items-center">
                    <div class="col-4 col-lg-12 text-start col-xl-4">
                        <span class="mb-2 d-inline-block" style="min-width: 200px;">
                            Fecha de inicio
                        </span>
                    </div>
                    <div class="col-8 col-lg-12 col-xl-8 p-lg-0 flex-center">
                        <label for="init-date" class="position-relative">
                            <img :src="calendar_icon" alt="icon">
                            <input ref="date_input" 
                                @change="filterElements"
                                class="date-picker d-none"
                                type="date" id="init-date" 
                                name="init-date" 
                                v-model="date_init" 
                                >
                            <span class="ml-3">
                                {{ date_init }}
                            </span>
                        </label>
                    </div>
                </div>
                
            </div>

            <div @click.stop="openDatepicker('final')"
                class="col-12 py-3 my-2 col-lg my-lg-0 mx-lg-2 py-0 bg-dark-gray d-flex"
                style="border-radius: 15px;">

                <div class="row align-items-center">
                    <div class="col-4 col-lg-12 text-start col-xl-4">
                        <span class="mb-2 d-inline-block" style="min-width: 200px;">
                            Fecha Actual
                        </span>
                    </div>
                    <div class="col-8 col-lg-12 col-xl-8 p-lg-0 flex-center">
                        <label for="final-date" class=" position-relative">
                            <img :src="calendar_icon" alt="icon">
                            <input ref="date_input_final" 
                                @change="filterElements"
                                class="date-picker d-none"
                                type="date" id="final-date" 
                                name="init-date" 
                                v-model="date_end" 
                                >
                            <span class="ml-3">
                                {{ date_end }}
                            </span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="col-12 py-3 col-lg bg-dark-gray d-flex flex-center"
                style="border-radius: 15px;">
                    <div class="position-relative w-100">
                        <input 
                            class="custom_input bg-dark-gray opensans-bold text-white pl-3 w-100"
                            type="text"
                            v-model="search_query"
                            placeholder="Buscar"
                            />
                        <img class="position-absolute"
                                style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                                :src="lupa_icon" />
                    </div>
            </div>
        </div>

        <!--Data filter --->
        <div class="row">

            <div class="col-12 col-xl-8" v-if="loading">
                <li-spinner size="sm"/>
            </div>

            <div class="col-12 col-xl-8" v-if="!loading && empty_search">
                <p>
                    No existen resultados para esa categoria o búsqueda.
                </p>
            </div>

            <div class="col-12 col-xl-8 order-2 order-xl-1" >

                <div class="row p-0" style="max-height: 730px; overflow-y: scroll;">
                    <div class="col-12 d-flex flex-wrap p-0">
                        <!--- indicents cards-->
                        <template v-if="type == 'incidents' && !loading">
                            <li-incident-card v-for="(incident ,index) in report_data" :key="`incident-${index}`"
                                class="my-2"
                                :color="incident.elapsed_days <= 5 ? 'success' : incident.elapsed_days >= 5 &&  incident.elapsed_days <= 10 ? 'warning' : 'danger' "
                                :description="incident.description"
                                :elapsed_days="incident.elapsed_days"
                                :responsible="incident.fullname_responsible"
                                :estimated_cost="incident.estimated_price"
                                :init_date="incident.creation"
                                :status="incident.incident_status"/>
                        </template>

                        <!--- Responsible cards-->
                        <template v-if="type == 'responsibles' && !loading">
                            <li-driver-card v-for="(responsible, index) in report_data" :key="`responsible-${index}`"
                                :color="responsible.quantity_incidents <= 3 ? 'success' : responsible.quantity_incidents > 3 && responsible.quantity_incidents <= 6 ? 'warning' : 'danger'"
                                :incidents="responsible.quantity_incidents"
                                :responsible="responsible.fullname_responsible ?? 'Incidencia sin asignar'"
                                @btn:handler="showDetails($event, responsible.id_user_responsible, 'responsible')"
                                class="m-2"/>
                        </template>

                        <!--- vehicle cards-->
                        <template v-if="type == 'vehicles' && !loading">
                            <li-vehicle-card v-for="(vehicle, index) in report_data" :key="`vehicle-${index}`"
                                :color="vehicle.elapsed_days <= 5 ? 'success' : vehicle.elapsed_days > 5 && vehicle.elapsed_days < 10  ? 'warning' : 'danger'"
                                :plate="vehicle.plate"
                                :brand="vehicle.brand_name"
                                :model="vehicle.model_name"
                                :estimated_cost="vehicle.estimated_price"
                                :elapsed_days="vehicle.elapsed_days"
                                :picture="vehicle.picture_vehicle"
                                :year="vehicle.year"
                                :creation="vehicle.creation_vehicle"
                                @handler="showDetails($event, vehicle.id_vehicle, 'vehicle')"
                                class="my-2" />
                        </template>
                    </div>
                </div>

                <div class="row p-0 mt-2">
                    <!---- Paginator ----->

                    <div class="col-12" v-if="!loading && !empty_search">
                        <div class="row justify-content-between">

                            <div class="col-12 col-md-5 col-lg-4 d-flex justify-content-center justify-content-lg-start align-items-center px-0 mt-2 mt-md-0">
                                <span class="mr-3 opensans-bold">
                                    Filas:
                                </span>
                                
                                <input @keyup.enter="navigate"
                                    type="number"
                                    style="width: 60px;"
                                    v-model="rows"
                                    :disabled="page > 1"
                                    class="form-control bg-secondary text-white border-0"
                                    />
                            </div>

                            <div class="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center justify-content-md-end">

                                <button @click.stop="prev"
                                    class="btn btn-pill btn-outline-primary"
                                    type="button"
                                    :disabled="page <= 1">
                                    <img :src="arrow_left" />
                                </button>

                                <input @keyup.enter="navigate"
                                    type="number"
                                    style="width: 60px;"
                                    v-model="page"
                                    class="form-control bg-secondary text-white border-0 mx-4 opensans-bold"
                                    />


                                <button @click.stop="next"
                                    class="btn btn-pill btn-outline-primary"
                                    type="button"
                                    :disabled="last_page">
                                    <img :src="arrow_left" style="transform: rotateY(180deg);" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="col-12 col-xl-4 order-1 order-xl-2 mb-4 mb-xl-0">
                <div class="row px-0 px-md-3">
                    <div class="col-12 py-3 p-0">
                        <button @click.stop="exportReport" v-if="false"
                            class="bg-dark-gray border-0 text-white opensans-bold w-100 py-3 d-flex justify-content-start"
                            style="border-radius: 15px; font-size: 20px;"
                            type="button">
                            <img class="mx-5" :src="export_icon" alt="">
                            Exportar
                        </button>
                    </div>

                    <div class="col-12 p-0 bg-dark-gray"
                        style="border-top-left-radius: 15px; border-top-right-radius: 15px;">
                        <div class="row py-3" >
                            <div class="col-2">
                                <img class="mx-4"
                                    :src="filter_icon" alt="filter">
                            </div>
                            <div class="col-8 text-start pl-4 opensans-bold" style="font-size: 24px">
                                <span class="text-start">
                                    Filtrar
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0 bg-dark-gray">
                        <div class="row pl-3">
                            <div class="col-12" v-for="(radio, index) in radio_types" :key="`radio-${index}`">
                                <button @click.stop="reportType(radio.value)"
                                    class="d-flex bg-dark-gray border-0 text-white opensans-bold d-flex flex-center my-3"
                                    type="button">
                                    <div :class="[id_incident_type == radio.value ? 'fill' : 'empty', 'mr-2']">
                                    </div>
                                    {{ radio.type}}
                                </button>
                            </div>
                        </div>
                    </div>                      
                </div>
            </div>
        </div>


        <!---Modal--->
        <li-modal :show_modal="vehicle_modal" :max_width="1000" class="px-3">
            <div class="col-12 d-flex flex-wrap px-3" style="height: 550px; overflow-y: scroll">
                <li-incident-card v-for="(incident ,index) in vehicle_detail" :key="`incident-vehicle${index}`"
                    class="my-2"
                    :color="incident.elapsed_days <= 5 ? 'success' : incident.elapsed_days >= 5 &&  incident.elapsed_days <= 10 ? 'warning' : 'danger' "
                    :description="incident.description"
                    :elapsed_days="incident.elapsed_days"
                    :responsible="incident.fullname_responsible"
                    :estimated_cost="incident.estimated_price"
                    :init_date="incident.creation"
                    :status="incident.incident_status"/>
            </div>

            <div class="col-12 d-flex justify-content-center mt-2">
                        
                <button @click.stop="vehicle_modal = false"
                    class="btn btn-pill text-white ml-3"
                    style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                    cerrar
                </button>
            </div>

        </li-modal>

        <li-modal :show_modal="responsible_modal" :max_width="1000" class="px-3">
            <div class="col-12 d-flex flex-wrap px-3 flex-center" style="height: 550px; overflow-y: scroll">
                <li-incident-card v-for="(incident ,index) in responsible_detail" :key="`incident-responsible-${index}`"
                    class="my-2"
                    :color="incident.elapsed_days <= 5 ? 'success' : incident.elapsed_days >= 5 &&  incident.elapsed_days <= 10 ? 'warning' : 'danger' "
                    :description="incident.description"
                    :elapsed_days="incident.elapsed_days"
                    :responsible="incident.fullname_responsible"
                    :estimated_cost="incident.estimated_price"
                    :init_date="incident.creation"
                    :status="incident.incident_status"/>
            </div>

            <div class="col-12 d-flex justify-content-center mt-2">
                        
                <button @click.stop="responsible_modal = false"
                    class="btn btn-pill text-white ml-3"
                    style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                    cerrar
                </button>
            </div>

        </li-modal>
    </div>
</template>

<script>
    // icons
    import lupa_icon from 'ASSETS/icons/lupa_icon.png'
    import calendar_icon from 'ASSETS/icons/calendar.svg'
    import export_icon from 'ASSETS/icons/export.svg'
    import filter_icon from 'ASSETS/icons/filter.svg'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                lupa_icon,
                calendar_icon,
                export_icon,
                filter_icon,
                arrow_left,
                search_query: '',
                loading: false,
                empty_search: false,
                radio_types: [
                    {
                        type: 'Todos',
                        value: null
                    },
                    {
                        type: 'Administrativo',
                        value: 1
                    },
                    {
                        type: 'Mecánica',
                        value: 2
                    },
                    {
                        type: 'Hojalatería y pintura ',
                        value: 3
                    },
                    {
                        type: 'Eléctrico ',
                        value: 4
                    },
                    {
                        type: 'Mantenimiento ',
                        value: 5
                    }
                ],
                //datatable
                type: 'incidents',
                vehicle_modal: false,
                responsible_modal: false,
                date_init: '___/___/___',
                id_incident_type: null,
                id_vehicle: null, 
                id_responsible: null,
                date_end: this.setCurrentDate(),
                id_responsible: null,
                id_vehicle: null,
                datatable: true,
                page: 1,
                rows: 10,
                search: '',
                order_by: 'id_incident',
                order_asc: true,
                filter: 'active'

            }
        },
        computed: {
            ...mapGetters({
                report_data: 'dashboard/getReportData',
                vehicle_detail: 'dashboard/getVehicleDetail',
                responsible_detail: 'dashboard/getResponsibleDetail',
                last_page: 'dashboard/getLastPage',
                total_pages: 'dashboard/getTotalPages'
            })
        },
        methods: {
            openDatepicker(type) {
                if(type == 'init') {
                    this.$refs.date_input.showPicker()
                }
                else {
                    this.$refs.date_input_final.showPicker()
                }
                
            },
            setCurrentDate() {
                const raw_date = new Date().toISOString();
                const date = raw_date.slice(0,10)
                return date
            },
            async showVehicleDetails(event,id) {
                this.id_vehicle = id
                await this.filterElements();
            },
            exportReport() {
                console.log('exported report')
            },
            async reportType(id) {
                this.id_incident_type = id;
                await this.filterElements()
            },
            async changeReport(type) {
                this.type = type
                await this.filterElements();
            },
            async showDetails(value, id, type) {
                console.log(id)
                if(type == 'vehicle') {
                    await this.$store.dispatch('dashboard/vehicleDetail', id)
                    console.log(this.vehicle_detail)
                    this.vehicle_modal = true
                }

                else {
                    await this.$store.dispatch('dashboard/responsibleDetail', id ?? 'NULL')
                    this.responsible_modal = true
                }
                
            },
            //pagination
            //helpers
            async filterElements(){
                this.loading = true
                let search = ''
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;

                let datatable = {
                    datatable: true, 
                    page: this.page,
                    rows: this.rows, 
                    search: search, 
                    order_asc: order, 
                    order_by: this.order_by, 
                    type: this.type, 
                    id_incident_type: this.id_incident_type, 
                    id_vehicle: this.id_vehicle, 
                    id_responsible: this.id_responsible,
                    init_date: this.date_init,
                    end_date: this.date_end
                }
                
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('dashboard/list', { status: 'active', ...datatable})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('dashboard/list', { status: 'inactive', ...datatable})
                        break;
                    case 'all':
                        await this.$store.dispatch('dashboard/list', { status: null, ...datatable})
                        break;
                }

                if(this.report_data === null) {
                    this.empty_search = true
                    this.loading = false
                    return
                }

                else {
                    this.empty_search = false
                }

                this.loading = false
                
            },
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                console.log('next')
                this.page++;
                this.filterElements()
            }
        },
        async mounted() {
            try {
                await this.filterElements();
                //list report data
                
            }

            catch(e) {
                console.log(e)
            }
        }
    }

</script>

<style lang="scss" scoped>
    #Dashboard {
        .custom_input {
            height: 40px;
            border: 1px solid white;
            border-radius: 30px;
            width: 100%;
            &::placeholder{
                padding-left: 15px;
                color: white;
            }
        }

        .fill {
            width: 20px;
            height: 20px;
            background-color: white;
            border-radius: 50%;
        }

        .empty {
            width: 20px;
            height: 20px;
            border: 2px solid white;
            border-radius: 50%;
        }

    }

</style>
